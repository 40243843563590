import React from 'react'

import { Alert } from '../Alert'

const ErrorBox = ({ errors = {} }) => {
  const keys = Object.keys(errors)

  if (!keys.length) {
    return null
  }

  const renderErrorItem = err => <li key={err}>{errors[err]}</li>

  return (
    <Alert
      message="Error"
      description={<ul>{keys.map(renderErrorItem)}</ul>}
      type="error"
      showIcon
    />
  )
}

export default ErrorBox
